import SEO from '@connected/SEO';
import loadable from '@loadable/component';
import { IngredientModel } from '@presentation/OurRumBanner';
import ProductDetails, { image, VariantModel } from '@presentation/ProductDetails';
import addToCart from '@redux/reducers/shopify/thunks/addToCart';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { isIngredient } from '@utils/guards';
import { graphql } from 'gatsby';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Contentful } from 'schema/Contentful';
import { ContentModels } from 'schema/ContentModels';
import { Shopify } from 'schema/Shopify';

const SectionsAsync = loadable(() => import('@components/connected/Sections'));

interface ProductPageProps {
  data: {
    productPage: ContentModels.ContentfulProduct;
  };
}

const ProductPage: React.FC<ProductPageProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.shopify);

  // Get the other variants for the same parent product - this is because Contentful
  // allows editors to select a variant rather than a product.
  const variantsInSameProduct = data.productPage.shopifyProduct?.product?.variants ?? [];

  return (
    <>
      <SEO data={data.productPage.metadata} url={data.productPage.url} />
      <ProductDetails
        title={data.productPage.title ?? ''}
        description={data.productPage.description?.description}
        id={data.productPage.shopifyProduct?.shopifyId}
        image={image(data.productPage.image?.[0]?.public_id)}
        ingredients={createIngredientArray(data.productPage.ingredients)}
        price={data.productPage.shopifyProduct?.priceNumber}
        subtitle={data.productPage.subtitle}
        variants={createVariantArray(variantsInSameProduct)}
        onAddToCart={(variantId, quantity) => {
          if (loading === 'idle') {
            dispatch(addToCart({ variantId, quantity }));
          }
        }}
      />
      {!!data.productPage.sections?.length && (
        <SectionsAsync sections={data.productPage.sections} />
      )}
    </>
  );
};

export default ProductPage;

function createVariantArray(variants?: Shopify.ProductVariant[]): VariantModel[] {
  // Making the assumption that we'd want to show the most expensive variant first.
  return orderBy(variants?.map(createVarientModel) ?? [], ['price'], ['desc']);
}

function createVarientModel(entry: Shopify.ProductVariant): VariantModel {
  return {
    id: entry.shopifyId ?? '',
    label: entry.selectedOptions?.[0]?.value ?? '',
    price: entry.priceNumber ?? 0,
  };
}

function createIngredientArray(entries?: Contentful.ContentfulEntry[]): IngredientModel[] {
  const ingredients = entries?.filter(isIngredient) ?? [];
  return ingredients.map(createIngredientModel);
}

function createIngredientModel(entry: ContentModels.ContentfulIngredient): IngredientModel {
  return {
    id: entry.id ?? '',
    icon: entry.icon?.[0]?.secure_url ?? '',
    title: entry.title ?? '',
  };
}

export const query = graphql`
  query ProductPageQuery($slug: String) {
    productPage: contentfulProduct(slug: { eq: $slug }) {
      contentful_id
      metadata {
        ...ContentfulMetadataFragment
      }
      description {
        description
      }
      image
      ingredients {
        id
        sys {
          contentType {
            sys {
              id
            }
          }
        }
        ... on ContentfulIngredient {
          icon
          title
        }
      }
      sections {
        ...LinkedItemsFragment
      }
      shopifyProduct {
        priceNumber
        shopifyId
        product {
          variants {
            priceNumber
            selectedOptions {
              name
              value
            }
            shopifyId
          }
        }
      }
      slug
      subtitle
      title
      url
    }
  }
`;
