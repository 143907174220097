import GatsbyImage, { FluidObject } from 'gatsby-image';
import React, { useState } from 'react';
import { IngredientItemProps, IngredientModel } from '@presentation/OurRumBanner';
import SectionWrapper, { SectionFlush } from '@presentation/SectionWrapper';
import s from './ProductDetails.styles';
import formatPrice from '@utils/formatPrice';
import ImageFrame from '@presentation/ImageFrame';
import imageUrl from '@utils/cloudinary';
import Markdown from 'markdown-to-jsx';
import useWasViewed from '@hooks/useWasViewed';
import { fadeRight, fadeUp } from '@utils/animations';

export interface VariantModel {
  id: string;
  label: string;
  price: number;
}

export interface ProductDetailsProps {
  id?: string;
  description?: string;
  image?: FluidObject;
  ingredients?: IngredientModel[];
  price?: number;
  subtitle?: string;
  title: string;
  variants?: VariantModel[];
  onAddToCart: (shopifyId: string, quantity: number) => void;
}

const IngredientItem: React.FC<IngredientItemProps> = ({ icon, title }) => {
  return (
    <s.IngredientItem {...fadeUp()}>
      <s.IngredientIconWrapper>
        <s.IngredientIcon src={icon} />
      </s.IngredientIconWrapper>
      <s.IngredientTitle>{title}</s.IngredientTitle>
    </s.IngredientItem>
  );
};

const ProductDetails: React.FC<ProductDetailsProps> = ({
  id,
  description,
  image,
  ingredients,
  price,
  subtitle,
  title,
  variants,
  onAddToCart,
}) => {
  const { ref: refInView, wasViewed } = useWasViewed({
    rootMargin: '0px 0px -50% 0px',
  });

  const [activeVariantId, setActiveVariantId] = useState(variants?.[0]?.id);
  const [quantity, setQuantity] = useState(1);

  const activeVariant = variants?.find((v) => v.id === activeVariantId);

  return (
    <SectionWrapper ref={refInView} flush={SectionFlush.TOP} padding overflow>
      <s.Wrapper animate={wasViewed ? 'visible' : 'hidden'}>
        <s.Container>
          <s.ImageColumn {...fadeRight()}>
            {image && (
              <ImageFrame>
                <s.Image>
                  <GatsbyImage fluid={image} />
                </s.Image>
              </ImageFrame>
            )}
          </s.ImageColumn>
          <s.DetailColumn variants={{ visible: { transition: { staggerChildren: 0.15 } } }}>
            <s.TitleContainer align="left" subtitle={subtitle} title={title} />
            <s.Trustpilot {...fadeUp()} />
            {!!variants?.length && (
              <>
                <s.VariantsTitle {...fadeUp()}>Choose option</s.VariantsTitle>
                <s.Variants {...fadeUp()}>
                  {variants.map((item) => (
                    <s.Variant
                      key={item.id}
                      active={activeVariantId === item.id}
                      onClick={(event) => {
                        event.preventDefault();
                        setActiveVariantId(item.id);
                      }}
                    >
                      {item.label}
                    </s.Variant>
                  ))}
                </s.Variants>
              </>
            )}
            {price && (
              <s.Price {...fadeUp()}>
                {formatPrice(activeVariant ? activeVariant.price : price)}
              </s.Price>
            )}
            <s.CallToAction {...fadeUp()}>
              <s.QuantityWrapper>
                <s.Increment
                  onClick={(event) => {
                    event.preventDefault();
                    setQuantity(quantity + 1);
                  }}
                >
                  {'+'}
                </s.Increment>
                <s.Decrement
                  onClick={(event) => {
                    event.preventDefault();
                    setQuantity(Math.max(quantity - 1, 1));
                  }}
                >
                  {'-'}
                </s.Decrement>
                <s.Quantity
                  min={1}
                  type="number"
                  value={quantity}
                  onChange={(event) => {
                    setQuantity(parseInt(event.target.value, 10));
                  }}
                />
              </s.QuantityWrapper>
              <s.AddToCart
                onClick={(event) => {
                  event.preventDefault();

                  const idToAdd = activeVariant?.id ?? id;

                  if (idToAdd) {
                    onAddToCart(idToAdd, quantity);
                  }
                }}
              >
                Buy Now
              </s.AddToCart>
            </s.CallToAction>
            {description && (
              <s.Content {...fadeUp()}>
                <Markdown>{description}</Markdown>
              </s.Content>
            )}
            {!!ingredients?.length && (
              <s.IngredientList variants={{ visible: { transition: { staggerChildren: 0.15 } } }}>
                {ingredients.map((item) => (
                  <IngredientItem key={item.id} {...item} />
                ))}
              </s.IngredientList>
            )}
          </s.DetailColumn>
        </s.Container>
      </s.Wrapper>
    </SectionWrapper>
  );
};

export default ProductDetails;

export function image(publicId?: string): FluidObject {
  return {
    aspectRatio: 420 / 560,
    sizes: '(max-width: 600px) 100vw, 600px',
    src: imageUrl(publicId, 'c_thumb,g_auto,w_600,ar_420:560'),
    srcSet: `
      ${imageUrl(publicId, 'c_thumb,g_auto,w_600,ar_420:560')} 600w,
      ${imageUrl(publicId, 'c_thumb,g_auto,w_900,ar_420:560')} 900w,
      ${imageUrl(publicId, 'c_thumb,g_auto,w_1200,ar_420:560')} 1200w
    `,
  };
}
