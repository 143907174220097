import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { Button } from '@presentation/Button';
import TitleContainer from '@presentation/TitleContainer';
import titleContainerStyles from '@presentation/TitleContainer/TitleContainer.styles';
import { Device, from, until } from '@utils/media';
import theme from '@utils/theme';
import { markdownStyles } from '@utils/markdown';

const Wrapper = styled(motion.div)`
  padding: 158px 0 0;

  @media ${from(Device.TabletLarge)} {
    padding: 224px 0 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${from(Device.TabletLarge)} {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(12, 1fr);
    place-items: flex-start;
  }
`;

const ImageColumn = styled(motion.div)`
  margin: 0 auto 32px;
  max-width: 420px;
  width: 100%;

  @media ${from(Device.TabletLarge)} {
    position: sticky;
    top: 56px;
    grid-column: 1 / span 6;
    margin: 0;
    max-width: 600px;
  }

  @media ${from(Device.DesktopSmall)} {
    grid-column: 2 / span 5;
  }
`;

const Image = styled.div`
  background: ${theme.brand.paper.default};
`;

const DetailColumn = styled(motion.div)`
  display: flex;
  flex-direction: column;

  @media ${from(Device.TabletLarge)} {
    grid-column: -7 / span 6;
  }

  @media ${from(Device.DesktopSmall)} {
    grid-column: -6 / span 5;
  }
`;

const StyledTitleContainer = styled(TitleContainer)`
  --title-color: ${theme.brand.blue.default};
  --subtitle-color: ${theme.brand.red.default};

  ${titleContainerStyles.Title} {
    font-size: 40px;
    line-height: 45px;

    @media ${from(Device.DesktopSmall)} {
      font-size: 60px;
      line-height: 70px;
    }
  }
`;

const Trustpilot = styled(motion.div)`
  margin-bottom: 24px;
`;

const VariantsTitle = styled(motion.h3)`
  color: ${theme.brand.blue.default};
  ${theme.fonts.venusian};
  font-size: 16px;
  letter-spacing: 0.05em;
  line-height: 18px;
  margin-bottom: 24px;
  text-transform: uppercase;

  @media ${from(Device.DesktopSmall)} {
    font-size: 18px;
    line-height: 20px;
  }
`;

const Variants = styled(motion.div)`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

export interface VariantProps {
  active: boolean;
}

const Variant = styled.button<VariantProps>`
  background: ${theme.brand.paper.default};
  border: 2px solid ${theme.brand.paper.dark};
  border-radius: 2px;
  color: ${theme.brand.paper.dark};
  cursor: pointer;
  flex: 1 1 50%;
  ${theme.fonts.venusian};
  font-size: 15px;
  line-height: 18px;
  min-width: 0;
  padding: 16px 32px;
  text-align: center;
  transition: border-color 0.3s ease-out, color 0.3s ease-out;

  @media ${from(Device.TabletLarge)} {
    font-size: 18px;
    letter-spacing: 0.05em;
    line-height: 20px;
    padding: 24px 56px;
  }

  & + & {
    margin-left: 16px;
  }

  ${({ active }) =>
    active
      ? css`
          border-color: ${theme.brand.red.default};
          color: ${theme.brand.blue.default};
        `
      : css`
          &:focus,
          &:hover {
            border-color: ${theme.brand.blue.default};
            color: ${theme.brand.blue.default};
          }
        `}
`;

const Price = styled(motion.div)`
  color: ${theme.brand.red.default};
  ${theme.fonts.venusian};
  font-size: 35px;
  line-height: 40px;
  margin-bottom: 24px;

  @media ${from(Device.DesktopSmall)} {
    font-size: 50px;
    line-height: 57px;
  }
`;

const CallToAction = styled(motion.div)`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

const QuantityWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
`;

const Quantity = styled.input`
  position: relative;
  appearance: none;
  background: ${theme.brand.paper.default};
  border: 2px solid ${theme.brand.paper.dark};
  border-radius: 24px;
  color: ${theme.brand.blue.default};
  flex: 1 1 50%;
  ${theme.fonts.venusian};
  font-size: 30px;
  grid-column: 1 / span 3;
  grid-row: 1;
  height: 48px;
  line-height: 33px;
  max-width: 134px;
  min-width: 0;
  padding: 8px 16px;
  text-align: center;
  width: auto;
  z-index: 1;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  @media ${from(Device.TabletLarge)} {
    border-radius: 32px;
    font-size: 40px;
    height: 64px;
    line-height: 45px;
    max-width: 246px;
  }
`;

const Increment = styled.button`
  position: relative;
  background: none;
  border: 0;
  color: ${theme.brand.blue.default};
  cursor: pointer;
  ${theme.fonts.brandon.bold};
  font-size: 32px;
  grid-column: 3;
  grid-row: 1;
  line-height: 1em;
  margin: 0;
  padding: 8px 16px;
  z-index: 2;

  @media ${from(Device.TabletLarge)} {
    font-size: 48px;
  }
`;

const Decrement = styled.button`
  position: relative;
  background: none;
  border: 0;
  color: ${theme.brand.blue.default};
  cursor: pointer;
  ${theme.fonts.brandon.bold};
  font-size: 32px;
  grid-column: 1;
  grid-row: 1;
  line-height: 1em;
  padding: 8px 16px;
  z-index: 2;
  transform: translateY(-4px);

  @media ${from(Device.TabletLarge)} {
    font-size: 48px;
  }
`;

const AddToCart = styled(Button)`
  flex: 1 1 50%;
  margin: auto 0 auto 24px;
  max-width: 246px;
`;

const Content = styled(motion.div)`
  ${markdownStyles};
  color: ${theme.brand.blue.dark};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 26px;
  margin-bottom: 24px;
  text-transform: uppercase;

  @media ${from(Device.DesktopSmall)} {
    font-size: 20px;
    letter-spacing: 0.02em;
    line-height: 28px;
  }

  p {
    margin-bottom: 1em;
  }
`;

const IngredientList = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -16px 32px;

  @media ${until(Device.Desktop)} {
    order: 4;
  }
`;

const IngredientItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin: 0 16px 16px;
  place-content: center;
  place-items: center;

  @media ${from(Device.Desktop)} {
    margin: 0 16px;
  }
`;

const IngredientIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${theme.brand.blue.dark};
  border-radius: 50%;
  height: 73px;
  margin-bottom: 8px;
  padding: 16px;
  place-content: center;
  place-items: center;
  width: 73px;

  @media ${from(Device.Tablet)} {
    height: 100px;
    width: 100px;
  }
`;

const IngredientIcon = styled.img`
  display: block;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  width: 100%;
`;

const IngredientTitle = styled.div`
  color: ${theme.brand.red.default};
  ${theme.fonts.brandon.bold};
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 23px;
  margin: 0;
  text-transform: uppercase;
  white-space: nowrap;
`;

export default {
  AddToCart,
  CallToAction,
  Container,
  Content,
  Decrement,
  DetailColumn,
  Image,
  ImageColumn,
  Increment,
  IngredientIcon,
  IngredientIconWrapper,
  IngredientItem,
  IngredientList,
  IngredientTitle,
  Price,
  Quantity,
  QuantityWrapper,
  TitleContainer: StyledTitleContainer,
  Trustpilot,
  Variant,
  Variants,
  VariantsTitle,
  Wrapper,
};
